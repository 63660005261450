import { AccordionTile, ButtonGroup, Divider, IconV2, Message } from "@roole/components-library";
import { monContratState } from "context/currentContratState/atom";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { ReferentielsPiecesJustificatives } from "domain/api-schemas";
import { declarationDataState } from "context/declarationState/atom";
import { format } from "date-fns";
import { listReferentielsPiecesJustificativesQuery } from "context/referentielsPiecesJustificativesState/selector";
import { getShortMaskedIban } from "utils/MaskedIban";
import { useStepperContext } from "./StepperContext";
import { ibanFormat } from "domain/service/Sinistre/useDossier";
import lodash from "lodash";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { classNames } from "utils/classNames";
import fr from "date-fns/locale/fr";

const IconWrapper = styled.span`
  & * {
    display: inline;
    width: min-content;
  }
`;
export const RecapStep: React.FC = () => {
  const { prevStep, setActions } = useStepperContext();
  const [declarationData] = useRecoilState(declarationDataState);
  const [monContrat] = useRecoilState(monContratState);
  const [fakePjs, setFakePjs] = useState<Array<string[]>>([]);

  const garantiesApplicables = declarationData?.typeEvenement?.garanties
    ?.filter((garantie) => {
      if (declarationData?.typeEvenement?.code === "VOL") {
        if (
          (!declarationData.isVolExpire &&
            declarationData.isVehiculeRetrouve &&
            !declarationData.isVehiculeReparable &&
            garantie.code === "AIDREP") ||
          (!declarationData.isVolExpire &&
            declarationData.isVehiculeRetrouve &&
            declarationData.isVehiculeReparable &&
            garantie.code === "IF") ||
          (declarationData.isVolExpire && garantie.code === "AIDREP")
        ) {
          return false;
        }
      }

      return true;
    })
    ?.filter((_) => (declarationData?.garantiesEligibles ?? []).includes(_.crmId));

  const garanties = useRecoilValueLoadable(
    listReferentielsPiecesJustificativesQuery({
      garantiesIds: declarationData!.typeEvenement!.garanties!.map((_) => _.crmId),
      typeEvenementId: declarationData!.typeEvenement!.crmId,
      isVehiculeRetrouve: !!declarationData?.isVehiculeRetrouve,
      isVehiculeReparable: !!declarationData?.isVehiculeReparable,
    }),
  );

  const garantiesEligibles =
    garanties.state === "hasValue" &&
    (garanties.contents as ReferentielsPiecesJustificatives[]).filter((_) =>
      (declarationData?.garantiesEligibles ?? []).includes(_.garantie.crmId),
    );

  const details = [
    { name: "Sinistre", detail: declarationData?.typeEvenement?.nom },
    {
      name: "Date",
      detail: format(new Date(declarationData?.dateEvenement!), "PPP", { locale: fr }),
    },
    { name: "Description", detail: declarationData?.description },
    {
      name: "Couverture assurance principale",
      detail: declarationData?.couvertureAssurance === "au-tiers" ? "Au tiers" : "Tout risques",
    },
    {
      name: "Vous avez déclaré le sinistre à votre assurance principale",
      detail: declarationData?.isDeclareAssuranceAccident ? "Oui" : "Non",
    },
  ];

  useEffect(() => {
    setActions({});

    if (garantiesEligibles && fakePjs.length === 0) {
      setFakePjs(garantiesEligibles.map((g) => g.piecesJustificatives.map((pj) => pj.crmId)));
    }
  }, [fakePjs, garantiesEligibles]);

  return (
    <div className="flex flex-col gap-M pt-M ">
      <p className="heading-2 ">Récapitulatif de votre déclaration</p>

      <div className="flex flex-col gap-XS ">
        <p className="heading-3 ">Détail du sinistre</p>

        <div className="flex flex-col gap-XS border border-default rounded-XS p-S ">
          {details.map((detail, index) => (
            <div className="flex flex-col gap-XS" key={`detail-${detail.name}`}>
              <div className="flex flex-col md:flex-row md:justify-between gap-2 ">
                <p className="flex-1 body-medium ">{detail.name}</p>
                <p className="flex-1 body-medium text-secondary md:text-right ">{detail.detail}</p>
              </div>

              {index !== details.length - 1 && <Divider direction="horizontal" variant="light" />}
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col gap-XS ">
        <div className="flex flex-col flex-3XS">
          <p className="heading-3 ">
            {declarationData?.garantiesEligibles?.length
              ? "Garanties applicables"
              : "Garantie applicable"}
          </p>
          <p className="body-small ">
            Sous réserve des vérifications menées par un conseiller Roole, une fois votre dossier
            complété.
          </p>
        </div>

        {garantiesApplicables?.map((garantie) => (
          <AccordionTile
            title={garantie.nom}
            caption="Informations complémentaires"
            textZone={garantie.plafondMessage}
            key={garantie.nom}
          >
            <div className="flex flex-col gap-XS">
              <div className="flex flex-col gap-XS">
                <div className="flex justify-between  ">
                  <p className="flex-1 body-medium ">Actes de vandalisme</p>
                  <p className="flex-1 body-medium text-secondary text-right ">Non</p>
                </div>

                <Divider direction="horizontal" variant="light" />
              </div>

              <div className="flex flex-col gap-XS">
                <div className="flex justify-between  ">
                  <p className="flex-1 body-medium ">Véhicule endommagé en stationnement</p>
                  <p className="flex-1 body-medium text-secondary text-right ">Non</p>
                </div>

                <Divider direction="horizontal" variant="light" />
              </div>

              <div className="flex flex-col gap-XS">
                <div className="flex justify-between  ">
                  <p className="flex-1 body-medium ">Vous êtes responsable</p>
                  <p className="flex-1 body-medium text-secondary text-right ">Oui</p>
                </div>

                <Divider direction="horizontal" variant="light" />
              </div>

              <div className="flex flex-col gap-XS">
                <div className="flex justify-between  ">
                  <p className="flex-1 body-medium ">Vous êtes le conducteur</p>
                  <p className="flex-1 body-medium text-secondary text-right ">Oui</p>
                </div>
              </div>
            </div>
          </AccordionTile>
        ))}
      </div>

      <div className="flex flex-col gap-XS ">
        <div className="flex flex-col flex-3XS">
          <p className="heading-3 ">Pièces justificatives</p>
          <p className="body-small ">
            Vous pourrez ajouter de nouvelles pièces justificatives plus tard, dans le menu “Mes
            sinistres”.
          </p>
        </div>

        {garantiesEligibles &&
          garantiesEligibles.map((garantie: ReferentielsPiecesJustificatives, garantieIndex) => {
            const pjs = garantie.piecesJustificatives.filter((v) =>
              fakePjs[garantieIndex]?.includes(v.crmId),
            );
            const pjsLeft = garantie.piecesJustificatives.length - pjs.length;
            const removePJ = (crmId: string) => {
              let _ = [...fakePjs];
              lodash.remove(_[garantieIndex], (v) => v === crmId);
              setFakePjs(_);
            };

            return (
              <div
                className="flex flex-col gap-XS border border-default rounded-XS p-S "
                key={garantie.garantie.nom}
              >
                <div className="flex flex-col md:flex-row md:items-center gap-4 justify-between  ">
                  <p className="flex-1 heading-4 ">{garantie.garantie.nom}</p>
                  <p
                    className={classNames(
                      "body-medium md:text-right ",
                      pjsLeft === 0 ? "text-positive" : "text-secondary",
                    )}
                    style={{ textWrap: "nowrap" }}
                  >
                    Justificatifs ajoutés :{" "}
                    <b>
                      {fakePjs[garantieIndex]?.length}/{garantie.piecesJustificatives.length}
                    </b>{" "}
                    {pjsLeft === 0 && (
                      <IconWrapper>
                        <IconV2 name="checked" color="positive" />
                      </IconWrapper>
                    )}
                  </p>
                </div>

                {pjs.map((pj, pjIndex) => (
                  <div
                    key={pj.crmId}
                    className="flex flex-col md:flex-row justify-between gap-2 "
                    onClick={() => removePJ(pj.crmId)}
                  >
                    <p className="flex-1 body-medium ">{pj.nom}</p>
                    <p className="body-medium text-secondary md:text-right ">
                      <IconWrapper>
                        <IconV2 name="file" color="neutral" />
                      </IconWrapper>{" "}
                      mon_fichier_{pjIndex}.jpg (148 Ko)
                    </p>
                  </div>
                ))}

                {pjsLeft > 0 && (
                  <Message
                    type="information"
                    icon="info"
                    message={
                      pjsLeft === garantie.piecesJustificatives.length ? (
                        <p className="body-small  ">
                          {pjsLeft} pièces justificatives sont nécessaires pour votre dossier.
                        </p>
                      ) : (
                        <p className="body-small ">
                          Il manque{" "}
                          {pjsLeft === 1
                            ? "1 pièce justificative"
                            : `${pjsLeft} pièces justificatives`}{" "}
                          à votre dossier.
                        </p>
                      )
                    }
                  />
                )}
              </div>
            );
          })}
      </div>

      <div className="flex flex-col gap-XS ">
        <p className="heading-3 ">Coordonnées bancaires</p>

        {!declarationData?.iban && (
          <div className="flex flex-col gap-XS border border-default rounded-XS p-S ">
            <div className="flex flex-col gap-XS">
              <div className="flex flex-col md:flex-row justify-between gap-2 ">
                <p className="flex-1 body-medium ">Mode de remboursement</p>
                <p className="body-medium text-secondary md:text-right ">Chèque</p>
              </div>

              <Divider direction="horizontal" variant="light" />
            </div>

            <div className="flex flex-col gap-XS">
              <div className="flex flex-col md:flex-row justify-between gap-2 ">
                <p className="flex-1 body-medium ">Titulaire</p>
                <p className="body-medium text-secondary md:text-right ">
                  {lodash.startCase(monContrat?.membre.prenom.toLowerCase())}{" "}
                  {lodash.startCase(monContrat?.membre.nom.toLowerCase())}
                </p>
              </div>

              <Divider direction="horizontal" variant="light" />
            </div>

            <div className="flex flex-col gap-XS">
              <div className="flex flex-col md:flex-row justify-between gap-2 ">
                <p className="flex-1 body-medium ">Adresse</p>
                <p className="body-medium text-secondary md:text-right capitalize ">
                  {lodash.startCase(monContrat?.membre.rue.toLocaleLowerCase())},{" "}
                  {lodash.startCase(monContrat?.membre.ville.toLocaleLowerCase())}{" "}
                  {monContrat?.membre.codePostal}
                </p>
              </div>
            </div>
          </div>
        )}
        {declarationData?.iban && (
          <div className="flex flex-col gap-XS border border-default rounded-XS p-S ">
            <div className="flex flex-col gap-XS">
              <div className="flex flex-col md:flex-row justify-between gap-2 ">
                <p className="flex-1 body-medium ">Mode de remboursement</p>
                <p className="body-medium text-secondary md:text-right ">Virement bancaire</p>
              </div>

              <Divider direction="horizontal" variant="light" />
            </div>

            <div className="flex flex-col gap-XS">
              <div className="flex flex-col md:flex-row justify-between gap-2 ">
                <p className="flex-1 body-medium ">Titulaire</p>
                <p className="body-medium text-secondary md:text-right ">
                  {lodash.startCase(monContrat?.membre.prenom.toLowerCase())}{" "}
                  {lodash.startCase(monContrat?.membre.nom.toLowerCase())}
                </p>
              </div>

              <Divider direction="horizontal" variant="light" />
            </div>

            <div className="flex flex-col gap-XS">
              <div className="flex flex-col md:flex-row justify-between gap-2 ">
                <p className="flex-1 body-medium ">IBAN</p>
                <p className="body-medium text-secondary md:text-right ">
                  {ibanFormat(getShortMaskedIban(declarationData?.iban))}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="self-center">
        <ButtonGroup
          buttonPrimary={{
            variant: "primary-brand",
            children: <p className="body-medium">Valider ma déclaration</p>,
            onClick: () => {
              alert("NEXT");
            },
          }}
          buttonSecondary={{
            variant: "secondary-dark",
            children: <p className="body-medium">Retour</p>,
            onClick: () => prevStep(),
          }}
          direction="row"
          size="large"
        />
      </div>
    </div>
  );
};
