import React, { FC, useState, useEffect } from "react";
import {
  ModalV2,
  Message,
  TextV2,
  Tile,
  ButtonUpload,
  ButtonGroup,
  ButtonV2,
} from "@roole/components-library";
import styles from "./UploadModal.module.scss";
import { useMatchMedia } from "hooks/useMatchMedia";
import { addPj } from "domain/api/Sinistre/AddPj";
import { DossierGarantie } from "domain/api-schemas";
import { validateAndSetFile, UploadedFile, AUTRES_TYPE_ID } from "./utils";
import { sendDossierEditedEvent } from "utils/sendEventToApp";
import { useAuthenticationContext } from "AuthenticationProvider";
import useMediaQuery from "hooks/useMediaQuery";

interface UploadModalProps {
  isOpen: boolean;
  handleClose: () => void;
  selectedPJ: { nom: string; description?: string; typeId?: string } | null;
  onFilesUpload: (files: UploadedFile[]) => void;
  dossierData: DossierGarantie;
  idAdhesion: number;
  maxFileSize?: number;
  fileTypeErrMessage?: string;
  fileSizeErrMessage?: string;
}

export const UploadModal: FC<UploadModalProps> = ({
  isOpen,
  handleClose,
  selectedPJ,
  onFilesUpload,
  dossierData,
  idAdhesion,
  maxFileSize = 5,
  fileTypeErrMessage = "Ce type de format n’est pas accepté.",
  fileSizeErrMessage = "Ce fichier dépasse la taille maximum autorisée.",
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const isMobile = useMatchMedia("mobile");
  const isSmallDesktop = useMediaQuery("max_small_desktop");
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const { isJwtAuthenticated } = useAuthenticationContext();

  useEffect(() => {
    if (isOpen) {
      setErrorMessage(null);
      setUploadedFiles([]);
      setIsUploading(false);
    }
  }, [isOpen]);

  const handleFileChange = async (file: File | null) => {
    const pjType = selectedPJ ? { typeId: selectedPJ.typeId } : { typeId: AUTRES_TYPE_ID };
    await validateAndSetFile(file, setErrorMessage, setUploadedFiles, pjType);
  };

  const handleDeleteFile = (fileName: string) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const handleValidate = () => {
    if (isUploading) {
      return;
    }
    setIsUploading(true);

    sendDossierEditedEvent();
    const uploadPromises = uploadedFiles.map((file) =>
      addPj({
        idAdhesion: idAdhesion,
        TypeId: file.typeId,
        DossierGarantieId: dossierData.crmId,
        EvenementId: dossierData.evenementId,
        Nom: file.name.split(".").slice(0, -1).join("."),
        Contenu: file.base64.split(",")[1],
        Extension: `.${file.name.split(".").pop()}`,
        Categorie: "Sinistre",
        Source: isJwtAuthenticated ? "RoolePremium" : "EspaceMembre",
      }),
    );

    Promise.all(uploadPromises)
      .then(() => {
        onFilesUpload(uploadedFiles);
        setUploadedFiles([]);
        setIsUploading(false);
        setErrorMessage(null);
        handleClose();
      })
      .catch((error) => {
        setIsUploading(false);
        setErrorMessage(
          "Une erreur est survenue lors de l'envoi des fichiers. Veuillez réessayer.",
        );
      });
  };

  const renderUploadedPJTiles = () => {
    return uploadedFiles.map((file) => (
      <Tile
        key={file.name}
        title={`${file.name} (${(file.size / 1024).toFixed(0)} Ko)`}
        subtitle={`Ajouté le ${file.date}`}
        avatar="file"
        icon="trash"
        label={isMobile ? "" : "Supprimer"}
        onClick={() => handleDeleteFile(file.name)}
      />
    ));
  };

  const primaryLabel = uploadedFiles.length > 0 ? "Valider" : "";

  return (
    <ModalV2
      title="Ajouter un document"
      isOpen={isOpen}
      handleClose={handleClose}
      content={
        <div className={styles.modal}>
          <Message
            icon="info"
            type="information"
            title="Consignes d'importation :"
            message={
              <>
                <TextV2 textVariant="body-small">
                  · Les formats acceptés sont : JPG, PNG, PDF.
                </TextV2>
                <TextV2 textVariant="body-small">
                  · La taille maximum est de : {maxFileSize} Mo par fichier.
                </TextV2>
                <TextV2 textVariant="body-small">
                  Assurez-vous que votre document n’est pas tronqué, ne comporte pas de flou ou de
                  reflet et qu’il est lisible.
                </TextV2>
              </>
            }
          />
          <TextV2 textVariant="body-medium-em">{selectedPJ?.nom || ""}</TextV2>
          <div className={styles.selectedPJ}>{renderUploadedPJTiles()}</div>
          <ButtonUpload
            onFileChange={handleFileChange}
            accept="image/jpeg, image/png, application/pdf"
            text={
              isSmallDesktop
                ? "Parcourir ma bibliothèque"
                : "Glisser-déposer ou importer depuis votre ordinateur"
            }
            icon="upload"
            errMessage={fileTypeErrMessage}
            sizeErrMessage={fileSizeErrMessage}
            maxFileSize={maxFileSize}
          />
          {errorMessage && (
            <div className={styles.errorMessage}>
              <Message
                icon="info"
                type="error"
                message={
                  <TextV2 textVariant="body-small" className={styles.errorText}>
                    {errorMessage}
                  </TextV2>
                }
              />
            </div>
          )}
          <div style={{ marginTop: 16 }}>
            {primaryLabel ? (
              <ButtonGroup
                buttonPrimary={{
                  children: primaryLabel,
                  onClick: () => {
                    if (!isUploading) {
                      handleValidate();
                    }
                  },
                  variant: "primary-brand",
                  disabledDark: isUploading,
                }}
                buttonSecondary={{
                  children: "Annuler",
                  onClick: handleClose,
                  variant: "secondary-dark",
                }}
              />
            ) : (
              <ButtonV2 variant="secondary-dark" onClick={handleClose}>
                Annuler
              </ButtonV2>
            )}
          </div>
        </div>
      }
    />
  );
};
