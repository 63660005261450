import { createContext, MouseEventHandler, ReactNode, useContext } from "react";

type ButtonV2Props = {
  children?: ReactNode;
  variant?:
    | "secondary-dark"
    | "primary-brand"
    | "primary-dark"
    | "primary-light"
    | "secondary-light"
    | "tertiary-brand"
    | "tertiary-dark";
  onClick?: MouseEventHandler<HTMLButtonElement>;
  id?: string;
};
type ButtonGroupActions = {
  buttonPrimary?: ButtonV2Props;
  buttonSecondary?: ButtonV2Props;
};

interface StepperContextType {
  setActions: (value: ButtonGroupActions) => void;
  prevStep: () => void;
  nextStep: () => void;
}

export const StepperContext = createContext<StepperContextType | undefined>(undefined);

export const useStepperContext = () => {
  const context = useContext(StepperContext);

  if (!context) {
    throw new Error("useStepperContext must be used within a StepperProvider");
  }

  return context;
};
