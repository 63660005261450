import { InputDate, TextV2 } from "@roole/components-library";
import { FC, useEffect, useState } from "react";
import styles from "./DateStep.module.scss";
import { useSetRecoilState } from "recoil";
import { declarationDataState } from "context/declarationState/atom";
import { DeclarationParams } from "domain/api/Sinistre/EvenementApi";
import { differenceInDays, format } from "date-fns";
import { useStepperContext } from "../StepperContext";
import { useTracking } from "hooks/useTracking";

export const DateStep: FC = () => {
  const { setActions, nextStep, prevStep } = useStepperContext();
  const setDeclarationData = useSetRecoilState(declarationDataState);
  const [value, setValue] = useState(new Date());

  const { sendToDataLayer } = useTracking();

  useEffect(() => {
    const dateEvenement = format(value, "yyyy-MM-dd");
    const dossier: DeclarationParams = {
      dateEvenement,
      isVolExpire: differenceInDays(new Date(), new Date(dateEvenement)) >= 30,
    };

    setDeclarationData(dossier);

    setActions({
      buttonPrimary: {
        variant: "primary-brand",
        children: "Continuer",
        onClick: (e) => {
          sendToDataLayer(e.currentTarget.id, true);
          nextStep();
        },
        id: "sinisterDeclare_selectEvent",
      },
      buttonSecondary: {
        variant: "secondary-dark",
        children: "Retour",
        onClick: () => prevStep(),
      },
    });
  }, [setDeclarationData, nextStep, prevStep, setActions, value]);

  return (
    <div className="pt-M">
      <TextV2 textVariant="heading-2" as="h2">
        À quelle date ?
      </TextV2>

      <div className={styles.consignes}>
        <TextV2 textVariant="body-medium">Indiquez-nous la date de votre sinistre.</TextV2>
      </div>

      <InputDate
        label="Date du sinistre"
        helper="Format attendue : JJ/MM/AAAA"
        blockAfterDate={new Date()}
        value={value}
        setValue={setValue}
      />
    </div>
  );
};
