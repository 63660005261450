import { FC, useMemo, useState, useEffect } from "react";
import { DossierGarantie, PjDescription, StatutPiece, PieceDossier } from "domain/api-schemas";
import {
  TextV2,
  ButtonV2,
  IconV2,
  Tile,
  ButtonGroup,
  Drawer,
  DrawerMobile,
  ModalV2,
  Message,
} from "@roole/components-library";
import { formatDate } from "utils/formatDate";
import { sendCloseWebViewEvent, sendDossierEditedEvent } from "utils/sendEventToApp";
import styles from "./Content.module.scss";
import { useMatchMedia } from "hooks/useMatchMedia";
import cssVariables from "styles/variables.module.scss";
import { UploadModal } from "./Upload/UploadModal";
import { UploadDrawer } from "./Upload/UploadDrawer";
import { RoutePaths } from "routes/routePaths";
import { useNavigate } from "react-router-dom";
import { listDescriptionPjQuery } from "context/descriptionPjState/selector";
import { useRecoilValueLoadable } from "recoil";
import { LoadableState } from "context/state.types";
import {
  handleViewDocument,
  matchPjDescription,
  stripHtmlTags,
  UploadedFile,
} from "domain/service/Sinistre/useDossier";
import { DemandeEtude } from "domain/api/Sinistre/DemandeEtude";
import { monContratWithEcheanceState } from "context/currentContratWithEcheanceState/atom";
import { useRecoilValue } from "recoil";
import { showPjApi } from "domain/api/Sinistre/ShowPjApi";

import { useAuthenticationContext } from "AuthenticationProvider";

interface NonConformeProps {
  dossierData: DossierGarantie;
}

export const NonConforme: FC<NonConformeProps> = ({ dossierData }) => {
  const monContrat = useRecoilValue(monContratWithEcheanceState);
  const { idAdhesion, identifiantContrat, membre } = monContrat ?? {};
  const isMobile = useMatchMedia(cssVariables.breakpointMobile);
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [selectedPJ, setSelectedPJ] = useState<{
    nom: string;
    typeId?: string;
    description?: string;
  } | null>(null);
  const [currentInfoPj, setCurrentInfoPj] = useState<PjDescription | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [allDocumentsAdded, setAllDocumentsAdded] = useState(false);
  const [isDemandeEtudeOpen, setIsDemandeEtudeOpen] = useState(false);

  const [isSended, setIsSended] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { isJwtAuthenticated } = useAuthenticationContext();

  const PJNonConformes = useMemo(
    () => dossierData.piecesDossiers?.filter((piece) => !piece.isConforme),
    [dossierData.piecesDossiers],
  );

  const typeIdArray = useMemo(() => PJNonConformes?.map((piece) => piece.typeId), [PJNonConformes]);

  const PJBloquantsNonConformesNonRecues = useMemo(
    () =>
      dossierData.piecesAttendues?.filter(
        (piece) =>
          piece.isBloquant &&
          ((typeIdArray?.includes(piece.typeId) && piece.statut !== StatutPiece.Conforme) ||
            piece.statut === StatutPiece.NonRecu),
      ),
    [dossierData.piecesAttendues, typeIdArray],
  );

  const pjInformations = useRecoilValueLoadable(
    listDescriptionPjQuery(PJBloquantsNonConformesNonRecues.map((pj) => pj.typeId)),
  );

  const handleOpenModal = (pj: { nom: string; typeId?: string }) => {
    setSelectedPJ(pj);
    setIsModalOpen(true);
  };

  const handleViewDocumentWithApi = async (pieceDossier: PieceDossier) => {
    try {
      const response = await showPjApi.fetchShowPj(
        pieceDossier.sharepointId || pieceDossier.blobNom || "",
      );
      const typePj = pieceDossier.nom.split(".").pop();
      const mimeType = typePj === "pdf" ? "application/pdf" : `image/${typePj}`;
      const base64 = `data:${mimeType};base64,${response}`;
      const newFile = {
        name: pieceDossier.nom,
        base64,
        size: 0,
        date: formatDate(new Date()),
        typeId: pieceDossier.typeId || "",
      };
      handleViewDocument(newFile);
    } catch (error) {
      console.error("Error fetching document", error);
    }
  };

  const handleFilesUpload = (files: UploadedFile[]) => {
    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
    setIsModalOpen(false);
  };

  const handleSendDocuments = () => {
    if (isSended) {
      return;
    }
    setIsSended(true);
    DemandeEtude({
      idAdhesion: Number(idAdhesion),
      dossierGarantie: {
        crmId: dossierData.crmId,
        nom: dossierData.nom,
      },
      garantie: {
        crmId: dossierData.garantie?.crmId,
        nom: dossierData.garantie?.nom,
      },
      evenement: {
        crmId: dossierData.evenementId,
        nom: dossierData.evenementNom,
      },
      typeEvenement: {
        crmId: dossierData.typeEvenement.crmId,
        nom: dossierData.typeEvenement.nom,
      },
      identifiantContrat: identifiantContrat || "",
      nomMembre: `${membre?.nom} ${membre?.prenom}`,
      Source: isJwtAuthenticated ? "RoolePremium" : "EspaceMembre",
    })
      .then(() => {
        sendDossierEditedEvent();
        setIsSended(false);
        setErrorMessage(null);
        setIsDemandeEtudeOpen(false);
        window.location.reload();
      })
      .catch((error) => {
        setErrorMessage("Une erreur est survenue lors de l'envoi du dossier. Veuillez réessayer.");
        setIsSended(false);
      });
  };

  const allCategoriesHaveDocuments = () => {
    const categories = PJBloquantsNonConformesNonRecues.filter(
      (category) => category.statut !== StatutPiece.Recu,
    );
    return categories.every((category) => {
      return uploadedFiles.some(
        (file) => file.typeId?.toLocaleLowerCase() === category.typeId?.toLocaleLowerCase(),
      );
    });
  };

  useEffect(() => {
    setAllDocumentsAdded(allCategoriesHaveDocuments());
  }, [dossierData, uploadedFiles]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const renderPiecesDossiers = (typeId: string) => {
    const pieces = dossierData.piecesDossiers?.filter(
      (piece) => piece.typeId?.toLowerCase() === typeId.toLowerCase(),
    );

    const filteredUploadedFiles = uploadedFiles.filter(
      (file) => file.typeId?.toLowerCase() === typeId.toLowerCase(),
    );

    if ((!pieces || pieces.length === 0) && filteredUploadedFiles.length === 0) {
      return (
        <TextV2 textVariant="body-small" color="secondary">
          Aucun document ajouté
        </TextV2>
      );
    }

    return (
      <>
        {pieces?.map((piece, index) => (
          <div key={`${piece.typeId}-${index}`}>
            <Tile
              title={piece.nom}
              subtitle={`Ajouté le ${formatDate(piece.dateAjout || piece.dateCreation)}`}
              avatar="file"
              icon="eye"
              label={isMobile ? "" : "Voir"}
              errorMessage={
                piece.isConforme === undefined || piece.isConforme || piece.isConforme === null
                  ? ""
                  : piece.motifRefus?.valeur && piece.commentaire
                  ? `${piece.motifRefus.valeur} • ${piece.commentaire}`
                  : piece.motifRefus?.valeur ||
                    `Justificatif non conforme${piece.commentaire ? ` • ${piece.commentaire}` : ""}`
              }
              onClick={() => handleViewDocumentWithApi(piece)}
            />
          </div>
        ))}
        {filteredUploadedFiles.map((file, index) => (
          <div key={`${file.name}-${index}`}>
            <Tile
              title={file.name}
              subtitle={`Ajouté le ${file.date}`}
              avatar="file"
              icon="eye"
              label={isMobile ? "" : "Voir"}
              onClick={() => handleViewDocument(file)}
            />
          </div>
        ))}
      </>
    );
  };

  return (
    <div className={styles.content}>
      <TextV2 textVariant="heading-2">Résultat de l'étude</TextV2>
      <TextV2 textVariant="body-medium" extraCss={{ marginTop: 8 }}>
        Oups, vous y êtes presque... Ajoutez les pièces justificatives demandées ci-dessous :
      </TextV2>
      <div>
        <div>
          {PJBloquantsNonConformesNonRecues?.map((pj) => {
            const pjInfo =
              pjInformations.state === LoadableState.Has_Value &&
              Array.isArray(pjInformations?.contents) &&
              matchPjDescription(pj.typeId, pjInformations?.contents);

            return (
              <div key={pj.typeId}>
                <div className={styles.pjHeader}>
                  <div className={styles.pjTitle}>
                    <TextV2 textVariant="body-medium-em">{pjInfo ? pjInfo.nom : pj.nom}</TextV2>
                    <IconV2
                      name="help-circle"
                      onClick={() => {
                        if (pjInfo) {
                          setIsInfoModalOpen(true);
                          setCurrentInfoPj(pjInfo);
                        }
                      }}
                      color={pjInfo ? "dark" : "neutral"}
                      extraCss={{ cursor: "pointer" }}
                    />
                  </div>
                  <ButtonV2
                    variant="tertiary-brand"
                    icon="plus"
                    size="small"
                    onClick={() => handleOpenModal(pj)}
                  >
                    Ajouter
                  </ButtonV2>
                </div>
                <div className={styles.pjList}>{renderPiecesDossiers(pj.typeId)}</div>
              </div>
            );
          })}
        </div>
        <div className={styles.footer}>
          <ButtonGroup
            size="large"
            direction={isMobile ? "column" : "row"}
            buttonSecondary={{
              variant: "secondary-dark",
              onClick: () => {
                sendCloseWebViewEvent({ hasError: false });
                if (!isJwtAuthenticated) {
                  navigate(RoutePaths.SINISTRES);
                }
              },
              children: "Retour à mes sinistres",
            }}
            buttonPrimary={{
              variant: "primary-brand",
              children: "Envoyer mon dossier",
              onClick: () => allDocumentsAdded && setIsDemandeEtudeOpen(true),
              disabledLight: !allDocumentsAdded,
            }}
          />
          <TextV2 textVariant="body-small" className={styles.footerText}>
            Pour envoyer, veuillez ajouter les documents nécessaires au traitement.
          </TextV2>
        </div>
      </div>
      {isMobile ? (
        <UploadDrawer
          isOpen={isModalOpen}
          handleClose={handleModalClose}
          selectedPJ={selectedPJ}
          onFilesUpload={handleFilesUpload}
          dossierData={dossierData}
          idAdhesion={Number(idAdhesion)}
        />
      ) : (
        <UploadModal
          isOpen={isModalOpen}
          handleClose={handleModalClose}
          selectedPJ={selectedPJ}
          onFilesUpload={handleFilesUpload}
          dossierData={dossierData}
          idAdhesion={Number(idAdhesion)}
        />
      )}
      {isMobile ? (
        <DrawerMobile
          title={currentInfoPj?.nom ?? ""}
          isOpen={isInfoModalOpen}
          handleClose={() => setIsInfoModalOpen(false)}
          content={<div>{currentInfoPj && stripHtmlTags(currentInfoPj.description)}</div>}
        />
      ) : (
        <Drawer
          title={currentInfoPj?.nom ?? ""}
          isOpen={isInfoModalOpen}
          handleClose={() => setIsInfoModalOpen(false)}
          content={<div>{currentInfoPj && stripHtmlTags(currentInfoPj.description)}</div>}
        />
      )}
      {isMobile ? (
        <DrawerMobile
          title={"Confirmer l'envoi"}
          isOpen={isDemandeEtudeOpen}
          handleClose={() => setIsDemandeEtudeOpen(false)}
          content={
            <div className={styles.modal}>
              <TextV2 textVariant="body-medium">
                En confirmant, votre dossier sera envoyé à l'un de nos conseillers pour être étudié.
                Vous n'aurez plus la possibilité d'ajouter de documents.
              </TextV2>
              {errorMessage && (
                <div style={{ marginTop: "8px" }}>
                  <Message
                    icon="info"
                    type="error"
                    message={
                      <TextV2 textVariant="body-small" color="error">
                        {errorMessage}
                      </TextV2>
                    }
                  />
                </div>
              )}
              <div className={styles.buttonsMobile}>
                <ButtonV2
                  variant="primary-brand"
                  onClick={() => {
                    if (!isSended) {
                      handleSendDocuments();
                    }
                  }}
                  disabledDark={isSended}
                >
                  Confirmer
                </ButtonV2>
                <ButtonV2 variant="secondary-dark" onClick={() => setIsDemandeEtudeOpen(false)}>
                  Annuler
                </ButtonV2>
              </div>
            </div>
          }
        />
      ) : (
        <ModalV2
          title={"Confirmer l'envoi"}
          isOpen={isDemandeEtudeOpen}
          handleClose={() => setIsDemandeEtudeOpen(false)}
          content={
            <div className={styles.modal}>
              <TextV2 textVariant="body-medium">
                En confirmant, votre dossier sera envoyé à l'un de nos conseillers pour être étudié.
                Vous n'aurez plus la possibilité d'ajouter de documents.
              </TextV2>
              {errorMessage && (
                <div style={{ marginTop: "16px" }}>
                  <Message
                    icon="info"
                    type="error"
                    message={
                      <TextV2 textVariant="body-small" color="error">
                        {errorMessage}
                      </TextV2>
                    }
                  />
                </div>
              )}
              <div className={styles.buttons}>
                <ButtonV2 variant="secondary-dark" onClick={() => setIsDemandeEtudeOpen(false)}>
                  Annuler
                </ButtonV2>
                <ButtonV2
                  variant="primary-brand"
                  onClick={() => {
                    if (!isSended) {
                      handleSendDocuments();
                    }
                  }}
                  disabledDark={isSended}
                >
                  Confirmer
                </ButtonV2>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default NonConforme;
