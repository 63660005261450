import { FC, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./Page.module.scss";
import {
  StepperDropdown,
  Stepper,
  ProgressStep,
  StepperItemProps,
  StepperDropdownItemProps,
  ButtonV2,
  ButtonGroup,
} from "@roole/components-library";
import cssVariables from "styles/variables.module.scss";
import { useMatchMedia } from "hooks/useMatchMedia";
import { currentContratQuery } from "context/currentContratState/selector";
import { tunnelLayoutState, defaultStateValues } from "context/tunnelLayoutState/atom";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { RoutePaths } from "routes/routePaths";
import { Footer } from "components/molecules/Footer/Footer";
import { GarantiesStep } from "./GarantiesStep";
import { PJs } from "./PJs";
import { NoGaranties } from "./NoGaranties";
import { declarationDataState } from "context/declarationState/atom";
import { DetailVolStep } from "./DeclarationStep/DetailVolStep";
import { DetailStep } from "./DeclarationStep/DetailStep";
import { ButtonGroupActions } from "./types";
import { StepperContext } from "./StepperContext";
import { DateStep } from "./DeclarationStep/DateStep";
import { EventStep } from "./DeclarationStep/EventStep";
import { CoordonneesBancaires } from "./CoordonneesBancaires";
import { checkIfTacite } from "domain/service/Contract/useMoncontrat";
import { RecapStep } from "./Recap";

const DATE_STEP = 0.1;
const EVENT_STEP = 0.33;
const DETAIL_STEP = 0.66;
const GARANTIES_STEP = 1;
const GARANTIES_ELIGIBLES_STEP = 2;
const COORDONNEES_BANCAIRES_STEP = 3;
const RECAP_STEP = 4;

export const Page: FC = () => {
  const contrat = useRecoilValueLoadable(currentContratQuery);
  const [declarationData, setDeclarationData] = useRecoilState(declarationDataState);
  const setLayoutState = useSetRecoilState(tunnelLayoutState);
  const setupLayoutState = (setLayoutState: (state: any) => void, handleClose: () => void) => {
    setLayoutState({
      ...defaultStateValues,
      layoutId: "header-logo",
      title: "Déclarer un sinistre",
      closeButtonId: "declarer-sinistre-croix",
      onCloseCallback: handleClose,
    });
  };
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate(RoutePaths.SINISTRES);
  }, [navigate]);

  useEffect(() => {
    setupLayoutState(setLayoutState, handleClose);
  }, [setLayoutState, handleClose]);

  const isMobileOrTablet = useMatchMedia(cssVariables.mobile_and_tablet);

  const STEPS = [
    DATE_STEP,
    EVENT_STEP,
    DETAIL_STEP,
    GARANTIES_STEP,
    GARANTIES_ELIGIBLES_STEP,
    checkIfTacite(contrat.getValue()!) ? 0 : COORDONNEES_BANCAIRES_STEP,
    RECAP_STEP,
  ].filter((v) => v !== 0);

  const [currentStep, setCurrentStep] = useState<(typeof STEPS)[number]>(0.1);
  const [actions, setActions] = useState<ButtonGroupActions>({});

  const stepperSteps: StepperItemProps[] = [
    {
      badge: 1,
      label: "Déclaration du sinistre",
      description: `En cours`,
      type: "active",
    },
    {
      badge: 2,
      label: "Complétion du dossier",
      description: "Vous ajoutez toutes les pièces justificatives obligatoires",
      type: "default",
    },
    {
      badge: 3,
      label: "Étude du dossier",
      description: "Nous vérifions les pièces justificatives dans les meilleurs délais",
      type: "default",
    },
    {
      badge: 4,
      label: "Conclusion du dossier",
      description: "Nous apportons la réponse à votre dossier",
      type: "default",
    },
  ];

  const transformedSteps: StepperDropdownItemProps[] = stepperSteps.map((step) => ({
    ...step,
    type: step.type === "filled" || step.type === "active" ? "active" : "default",
  }));

  const steps = [
    "Détail du sinistre",
    "Garanties",
    "Pièces justificatives",
    "Coordonnées bancaires",
    "Récapitulatif",
  ];

  const isVolVehicule = declarationData?.typeEvenement?.code === "VOL";
  const noGaranties = !!(
    currentStep === GARANTIES_ELIGIBLES_STEP &&
    (declarationData === undefined ||
      !declarationData?.typeEvenement ||
      ((declarationData.garantiesEligibles?.length ?? 0) === 0 &&
        (declarationData.garantiesEligibles?.length ?? 0) +
          (declarationData.garantiesNonEligibles?.length ?? 0)))
  );

  useEffect(() => {
    const monContrat = contrat.getValue();

    if (currentStep === STEPS[0] && monContrat && !declarationData?.iban) {
      setDeclarationData({
        ...(declarationData as any),
        iban: monContrat.coordonneesBancaires.iban,
      });
    }
  }, [contrat, declarationData]);

  const stepsContents = {
    [DATE_STEP.valueOf()]: <DateStep />,
    [EVENT_STEP.valueOf()]: <EventStep />,
    [DETAIL_STEP.valueOf()]: isVolVehicule ? <DetailVolStep /> : <DetailStep />,
    [GARANTIES_STEP.valueOf()]: <GarantiesStep />,
    [GARANTIES_ELIGIBLES_STEP.valueOf()]: noGaranties ? <NoGaranties /> : <PJs />,
    [COORDONNEES_BANCAIRES_STEP.valueOf()]: <CoordonneesBancaires />,
    [RECAP_STEP.valueOf()]: <RecapStep />,
  };

  const isMobile = useMatchMedia(cssVariables.breakpointMobile);

  const contextValue = useMemo(
    () => ({
      setActions,
      prevStep: () => {
        const currentIndex = STEPS.indexOf(currentStep);

        if (currentIndex === -1) {
          throw new Error("Une erreur s'est produite");
        }
        if (currentIndex === 0) {
          navigate(RoutePaths.DECLARER_SINISTRES);
        } else {
          setCurrentStep(STEPS[currentIndex - 1]);
        }
      },
      nextStep: () => {
        const currentIndex = STEPS.indexOf(currentStep);

        if (currentIndex === -1) {
          throw new Error("Une erreur s'est produite");
        }

        setCurrentStep(STEPS[currentIndex + 1]);
      },
    }),
    [currentStep, setCurrentStep, setActions, navigate],
  );

  return (
    <StepperContext.Provider value={contextValue}>
      <div className={noGaranties ? styles.noGaranties : styles.mainContainer}>
        {!noGaranties && (
          <div className={styles.stepper}>
            {isMobileOrTablet ? (
              <StepperDropdown steps={transformedSteps} defaultValue={0} />
            ) : (
              <Stepper steps={stepperSteps} />
            )}
          </div>
        )}

        <div className={styles.container}>
          {!noGaranties && <ProgressStep steps={steps} currentStep={currentStep} />}

          {stepsContents[currentStep]}

          {(!!actions.buttonSecondary || !!actions.buttonPrimary) && (
            <div className={styles.footer}>
              {!!actions.buttonSecondary && !actions.buttonPrimary && (
                <ButtonV2 {...actions.buttonSecondary} />
              )}
              {!!actions.buttonSecondary && !!actions.buttonPrimary && (
                <ButtonGroup
                  size="large"
                  direction={isMobile ? "column" : "row"}
                  buttonPrimary={actions.buttonPrimary}
                  buttonSecondary={actions.buttonSecondary}
                />
              )}
            </div>
          )}
        </div>
      </div>

      <Footer contrat={contrat?.contents || null} idAdhesion={null} />
    </StepperContext.Provider>
  );
};
