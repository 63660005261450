import { useWizard, WizardNav, WizardStep, WizardStepLayout } from "components/molecules/Wizard";
import { renewalState } from "context/renewalState/atom";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { WizardActionType } from "components/molecules/Wizard/WizardAction";
import { Loader, Text } from "@roole/components-library";
import styles from "./RenewalPaymentStep.module.scss";
import { classNames } from "utils/classNames";
import { Box } from "components/atoms/Box/Box";
import { CreatePaymentData } from "domain/api-schemas";
import { paiementCbApi } from "domain/api/PaiementCb/paiementCbApi";
import { usePaiement } from "hooks/usePaiement";
import { hostedTokenizationIdState } from "context/hostedTokenizationIdState/atom";
import { useTracking } from "hooks/useTracking";
import axios from "axios";

export const RenewalPaymentStep: WizardStep = () => {
  const wizard = useWizard();
  const [hostedTokenizationId] = useRecoilState(hostedTokenizationIdState);
  const [loader, setLoader] = useState(true);
  const { tokenizer, showIframe, submitFormTokenization } = usePaiement();
  const [renewal] = useRecoilState(renewalState);
  const { sendToDataLayer } = useTracking();

  useEffect(() => {
    if (renewal.id) {
      showIframe(renewal.id);
    }
  }, [renewal]);

  const prevStepHandler = () => wizard.gotoStep(wizard.indexPath);

  useEffect(() => {
    if (tokenizer !== undefined) {
      tokenizer
        .initialize()
        .then(() => {
          setLoader(false);
        })
        .catch((err: any) => {
          wizard.dispatch({
            type: WizardActionType.INIT_WIZARD_FAILED,
            error: "Erreur, veuillez réessayer",
            errorNextLabel: "Relancer le paiement",
          });
          if (renewal.id) {
            showIframe(renewal.id);
          }
        });
    }

    return () => {
      if (tokenizer !== undefined) {
        tokenizer.destroy();
      }
    };
  }, [tokenizer]);

  useEffect(() => {
    const processPayment = async () => {
      if (hostedTokenizationId !== "" && renewal.id !== undefined) {
        sendToDataLayer("reconduction-paiement", false, renewal.id);
        setLoader(true);

        try {
          const { data } = await axios.get("https://api.ipify.org?format=json");
          const { ip } = data;

          if (!ip) {
            throw new Error("Ip address service not working");
          }

          const paymentData: CreatePaymentData = {
            hostedTokenizationId: hostedTokenizationId,
            returnUrl: window.location.href,
            montantAPayer: renewal.montantAPayer ?? 0,
            userAgent: window.navigator.userAgent,
            language: window.navigator.language,
            colorDepth: window.screen.colorDepth,
            screenHeight: window.screen.height.toString(),
            screenWidth: window.screen.width.toString(),
            timezoneOffset: new Date().getTimezoneOffset().toString(),
            emailAddress: renewal.emailAddress,
            phoneNumber: renewal.mobilePhone,
            IpAddress: ip,
            type: "reconduction",
            transactionCbId: null,
          };

          const resultPayment: any = await paiementCbApi.createPayment(paymentData, renewal.id);

          if (resultPayment.payment.status === "REDIRECTED") {
            window.location.href = resultPayment.merchantAction.redirectData.redirectUrl;
          } else if (resultPayment.payment.status === "CAPTURED") {
            wizard.submitStep();
          }
        } catch (err) {
          setLoader(false);
          wizard.dispatch({
            type: WizardActionType.SUBMIT_STEP_FAILED,
            error: "Le paiement n'a pas abouti. Veuillez réessayer",
            errorNextLabel: "Relancer le paiement",
          });

          if (renewal.id) {
            showIframe(renewal.id);
          }
        }
      }
    };

    processPayment(); // Appelle la fonction asynchrone
  }, [hostedTokenizationId, renewal.montantAPayer, renewal.id]);

  return (
    <WizardStepLayout
      title="Paiement de votre cotisation"
      subtitle="Veuillez saisir vos informations de carte bancaire afin d'effectuer le paiement et finaliser la reconduction de votre contrat."
    >
      {loader && (
        <div className={classNames(styles.center)}>
          <div className={classNames(styles.loaderContainer)}>
            <Loader />
          </div>
        </div>
      )}
      <Box className={classNames(styles.paymentBox)}>
        <div>
          {!loader && (
            <Box className={classNames(styles.greyBox, styles.mt0)}>
              <Text textVariant="heading-6" as="h3">
                Résumé de la commande
              </Text>
              <Text textVariant="body" as="p">
                Total à payer: {renewal.montantAPayer?.toFixed(2)}€
              </Text>
            </Box>
          )}
          <div id="div-hosted-tokenization"></div>
        </div>
        {!loader && (
          <div>
            <Text textVariant="heading-5" as="h2">
              Nous prenons en charge les frais non couverts par votre assurance principale
            </Text>
            <Box className={classNames(styles.bullet)}>
              <ul>
                <li>
                  <span>
                    <b>Rachat de franchise</b> : prise en charge de votre franchise, notamment en
                    cas d'accident responsable.
                  </span>
                </li>
                <li>
                  <span>
                    <b>Assistance 0 km</b> : prise en charge du dépannage et/ou du remorquage de
                    votre véhicule.
                  </span>
                </li>
                <li>
                  <span>
                    <b>Véhicule de remplacement</b> : mise à disposition immédiate en cas d'aléas
                    sans délai ni avance de fonds.
                  </span>
                </li>
              </ul>
            </Box>
          </div>
        )}
      </Box>

      <WizardNav
        prevStepLabel="Accueil"
        prevStepHandler={prevStepHandler}
        nextStepHandler={() => submitFormTokenization(renewal.id)}
        disabled={loader}
      />
    </WizardStepLayout>
  );
};
