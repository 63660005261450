import axiosClient from "clientProvider/axiosConfig";
import { Evenement, EvenementType, RepriseEvenementType } from "domain/api-schemas";

export type TypesEvenementsParams = {
  ageContrat: number;
  code: string;
  dateEvenement: string;
};

export type TypesInfoRepriseParams = {
  idAdhesion: string;
  dateEvenement: string;
  typeEvenementId: string;
};

export type DeclarationParams = {
  dateEvenement: string;
  isVolExpire?: boolean;
  typeEvenement?: EvenementType;
  description?: string;
  isVehiculeRetrouve?: boolean | null;
  isVehiculeReparable?: boolean | null;
  isDeclareAssuranceAccident?: boolean;
  couvertureAssurance?: "tous-risques" | "au-tiers";
  garantiesEligibles?: string[];
  garantiesNonEligibles?: string[];
  iban?: string;
};

export const evenementApi = {
  fetchEvenements: async (idAdhesion: string) => {
    const result = await axiosClient.get<Evenement[]>(`Evenement/Membre/${idAdhesion}`);
    return result.data;
  },

  fetchTypesEvenements: async (params: TypesEvenementsParams) => {
    const result = await axiosClient.get<EvenementType[]>(`Evenement/Types/Produit/`, {
      params,
    });
    return result.data;
  },

  fetchInfoRepriseEvenement: async ({
    idAdhesion,
    dateEvenement,
    typeEvenementId,
  }: TypesInfoRepriseParams) => {
    const result = await axiosClient.get<RepriseEvenementType>(
      `Evenement/InfosRepriseEvenement/${idAdhesion}/${dateEvenement}/${typeEvenementId}`,
    );

    return result.data;
  },
};
