import { WizardNav, WizardStep, WizardStepLayout, useWizard } from "components/molecules/Wizard";
import { Text, Loader } from "@roole/components-library";
import { useState, useEffect } from "react";
import { WizardActionType } from "components/molecules/Wizard/WizardAction";
import { classNames } from "utils/classNames";
import styles from "./RegulStep.module.scss";
import { usePaiement } from "hooks/usePaiement";
import { echeancierState } from "./Context/EcheancierState/atom";
import { useRecoilState, useRecoilValue } from "recoil";
import { hostedTokenizationIdState } from "context/hostedTokenizationIdState/atom";
import { CreatePaymentData } from "domain/api-schemas";
import { paiementCbApi } from "domain/api/PaiementCb/paiementCbApi";
import { regularisationApi } from "domain/api/Regularisation/regularisationApi";
import { monContratState } from "context/currentContratState/atom";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "routes/routePaths";
import { regularisationState } from "context/regularisationState/atom";
import { useTracking } from "hooks/useTracking";
import axios from "axios";

export const RegulStep: WizardStep = () => {
  const wizard = useWizard();
  const { tokenizer, showIframe, submitFormTokenization } = usePaiement();
  const [loader, setLoader] = useState(true);
  const echeancier = useRecoilValue(echeancierState);
  const [hostedTokenizationId] = useRecoilState(hostedTokenizationIdState);
  const [transactionCbId, setTransactionCbId] = useState(null);
  const monContrat = useRecoilValue(monContratState);
  const [dossierId, setDossierId] = useRecoilState(regularisationState);
  const navigate = useNavigate();
  const { sendToDataLayer } = useTracking();

  useEffect(() => {
    regularisationApi
      .createDossier(monContrat?.idAdhesion, monContrat?.membre.email)
      .then((result: any) => {
        if (result.dossierRegularisationId) {
          setDossierId(result.dossierRegularisationId);
          setTransactionCbId(result.transactionCbId);
          showIframe(result.dossierRegularisationId);
          setLoader(false);
        } else {
          setLoader(false);
          wizard.dispatch({
            type: WizardActionType.INIT_WIZARD_FAILED,
            error: "Erreur, veuillez réessayer",
          });
          wizard.gotoStep("/");
        }
      })
      .catch((err: any) => {
        setLoader(false);
        wizard.dispatch({
          type: WizardActionType.SUBMIT_STEP_FAILED,
          error: "Erreur, veuillez réessayer",
        });
        wizard.gotoStep("/");
      });
  }, [monContrat]);

  useEffect(() => {
    if (tokenizer !== undefined) {
      tokenizer
        .initialize()
        .then(() => {
          setLoader(false);
        })
        .catch((err: any) => {
          wizard.dispatch({
            type: WizardActionType.INIT_WIZARD_FAILED,
            error: "Erreur, veuillez réessayer",
          });
          if (dossierId) {
            showIframe(dossierId);
          }
        });
    }

    return () => {
      if (tokenizer !== undefined) {
        tokenizer.destroy();
      }
    };
  }, [tokenizer]);

  useEffect(() => {
    const processRegularisationPayment = async () => {
      if (hostedTokenizationId !== "" && dossierId) {
        setLoader(true);
        sendToDataLayer("regularisation-paiement", false);

        try {
          const { data } = await axios.get("https://api.ipify.org?format=json");
          const { ip } = data;

          if (!ip) {
            throw new Error("Ip address service not working");
          }

          const paymentData: CreatePaymentData = {
            hostedTokenizationId: hostedTokenizationId,
            returnUrl: window.location.href,
            montantAPayer: echeancier?.montantTotal ?? 0,
            userAgent: window.navigator.userAgent,
            language: window.navigator.language,
            colorDepth: window.screen.colorDepth,
            screenHeight: window.screen.height.toString(),
            screenWidth: window.screen.width.toString(),
            timezoneOffset: new Date().getTimezoneOffset().toString(),
            emailAddress: monContrat?.membre.email,
            phoneNumber: monContrat?.membre.telephoneMobile,
            IpAddress: ip,
            type: "regularisation",
            transactionCbId: transactionCbId,
          };

          const resultPayment: any = await paiementCbApi.createPayment(paymentData, dossierId);

          if (resultPayment.payment.status === "REDIRECTED") {
            window.location.href = resultPayment.merchantAction.redirectData.redirectUrl;
          } else if (resultPayment.payment.status === "CAPTURED") {
            wizard.submitStep();
          }
        } catch (err) {
          setLoader(false);
          wizard.dispatch({
            type: WizardActionType.SUBMIT_STEP_FAILED,
            error: "Le paiement n'a pas abouti. Veuillez réessayer",
            errorNextLabel: "Relancer le paiement",
          });
          showIframe(dossierId);
        }
      }
    };

    processRegularisationPayment(); // Appelle la fonction asynchrone
  }, [hostedTokenizationId, dossierId, echeancier, monContratState, transactionCbId]);

  return (
    <WizardStepLayout title={""}>
      {loader && (
        <div className={classNames(styles.center)}>
          <div className={classNames(styles.loaderContainer)}>
            <Loader />
          </div>
        </div>
      )}
      {!loader && (
        <>
          <Text textVariant="heading-4" as="h3">
            Régulariser ma facture impayée
          </Text>
          <Text textVariant="body" as="p">
            Montant: {echeancier?.montantTotal ?? 0}€
          </Text>
        </>
      )}
      <div id="div-hosted-tokenization"></div>
      <WizardNav
        prevStepLabel="Annuler"
        prevStepHandler={() => navigate(RoutePaths.CONTRAT)}
        nextStepHandler={() => submitFormTokenization(dossierId)}
        disabled={loader}
      />
    </WizardStepLayout>
  );
};
