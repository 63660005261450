import {
  AccordionTile,
  ButtonV2,
  Drawer,
  DrawerMobile,
  IconV2,
  Message,
  ModalV2,
  TextV2,
} from "@roole/components-library";
import { declarationDataState } from "context/declarationState/atom";
import { GarantieV2, ReferentielsPiecesJustificatives } from "domain/api-schemas";
import { FC, useEffect, useState } from "react";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import cssVariables from "styles/variables.module.scss";
import { useMatchMedia } from "hooks/useMatchMedia";
import { classNames } from "utils/classNames";
import styled from "styled-components";
import { listReferentielsPiecesJustificativesQuery } from "context/referentielsPiecesJustificativesState/selector";
import { stripHtmlTags } from "domain/service/Sinistre/useDossier";
import { useStepperContext } from "./StepperContext";

const HelperWrapper = styled.div`
  &,
  & * {
    display: inline;
  }
`;

type RenderPJProps = {
  Message?: {
    message: JSX.Element;
    type: "information" | "information2" | "alert" | "error" | "warning" | "validation";
    icon?: string;
  };

  docs: any[];

  pjName: string;
  onClickHelper?: () => void;
};

const RenderPJ: React.FC<RenderPJProps> = ({
  Message: MessageProps,
  docs,
  pjName,
  onClickHelper,
}) => {
  return (
    <div className={classNames("flex flex-col", !!MessageProps ? "gap-2XS" : "gap-3XS")}>
      <div className={classNames("flex justify-between items-start")}>
        <div className="flex-1">
          <TextV2 textVariant="body-medium-em" extraCss={{ display: "inline" }}>
            {pjName}{" "}
            {onClickHelper !== undefined && (
              <>
                <HelperWrapper>
                  <IconV2
                    name="help-circle"
                    size={28}
                    onClick={onClickHelper}
                    color="dark"
                    extraCss={{ cursor: "pointer" }}
                  />
                </HelperWrapper>{" "}
              </>
            )}
          </TextV2>
        </div>

        <ButtonV2
          variant="tertiary-brand"
          icon="plus"
          // onClick={() => handleOpenModal(pj)}
          size="small"
        >
          Ajouter
        </ButtonV2>
      </div>

      {MessageProps && <Message {...MessageProps} />}

      <div className="flex flex-col gap-8">
        {!docs?.length && (
          <TextV2 textVariant="body-small" color="secondary">
            Aucun document ajouté
          </TextV2>
        )}
      </div>
    </div>
  );
};

export const PJs: FC = () => {
  const { setActions, prevStep, nextStep } = useStepperContext();
  const [declarationData] = useRecoilState(declarationDataState);
  const [openModal, setOpenModal] = useState(false);
  const [pjInfo, setPjInfo] = useState<{ name: string; description: string } | undefined>(
    undefined,
  );
  const [selectedGarantie, setSelectedGarantie] = useState<GarantieV2 | null>(null);
  const [garantiesEligibles, setGarantiesEligibles] = useState<string[]>([]);
  const [garantiesNonEligibles, setGarantiesNonEligibles] = useState<string[]>([]);
  const garanties = useRecoilValueLoadable(
    listReferentielsPiecesJustificativesQuery({
      garantiesIds: declarationData!.typeEvenement!.garanties!.map((_) => _.crmId),
      typeEvenementId: declarationData!.typeEvenement!.crmId,
      isVehiculeRetrouve: !!declarationData?.isVehiculeRetrouve,
      isVehiculeReparable: !!declarationData?.isVehiculeReparable,
    }),
  );

  const isMobile = useMatchMedia(cssVariables.breakpointMobile);

  const handleClose = () => {
    setSelectedGarantie(null);
    setOpenModal(false);
  };

  const checkEligible = () => {
    if (selectedGarantie) {
      const isEligible = false;
      if (isEligible) {
        setGarantiesEligibles([...garantiesEligibles, selectedGarantie?.crmId]);
      } else {
        setGarantiesNonEligibles([...garantiesNonEligibles, selectedGarantie?.crmId]);
      }
      setOpenModal(false);
    }
  };

  const modalContent = (
    <div>
      <TextV2 textVariant="heading-3" as="h3">
        Complétez les informations
      </TextV2>

      <div className="flex flex-col-reverse gap-XS mt-M lg:flex-row">
        <ButtonV2 variant="secondary-dark" onClick={handleClose}>
          Annuler
        </ButtonV2>
        <ButtonV2 variant="primary-brand" onClick={() => checkEligible()}>
          Vérifier mon éligibilité
        </ButtonV2>
      </div>
    </div>
  );

  useEffect(() => {
    if (!declarationData) {
      return;
    }

    setActions({
      buttonPrimary: {
        variant: "primary-brand",
        children: "Continuer",
        onClick: () => {
          if (
            !!declarationData.garantiesEligibles?.length ||
            declarationData.garantiesNonEligibles?.length ===
              declarationData?.typeEvenement?.garanties?.length
          ) {
            nextStep();
          } else {
            alert("Il faut avoir au moins une garantie eligible");
          }
        },
      },
      buttonSecondary: {
        variant: "secondary-dark",
        children: "Retour",
        onClick: () => prevStep(),
      },
    });
  }, [declarationData, nextStep, prevStep, setActions]);

  return (
    <div className="pt-S md:pt-M flex flex-col gap-M">
      {/* <NoGaranties /> */}

      <div className="flex flex-col gap-XS">
        <TextV2 textVariant="heading-2" as="h2">
          Pièces justificatives
        </TextV2>

        <TextV2>
          Voici la liste des pièces justificatives obligatoires qui vous seront demandées avant
          l'étude de votre dossier par nos conseillers.
          <br /> Vous pouvez d'ores et déjà les ajouter ou compléter votre dossier{" "}
          <b>plus tard, depuis le menu "Mes sinistres".</b>{" "}
        </TextV2>

        <Message
          icon="info"
          message={
            <TextV2 textVariant="body-small">
              Si vous ajoutez toutes vos pièces justificatives maintenant, votre dossier passera en
              étude dès la fin de votre déclaration.
            </TextV2>
          }
          type="information"
        />
      </div>

      <div className="flex flex-col gap-XS">
        {garanties.state === "hasValue" &&
          (garanties.contents as ReferentielsPiecesJustificatives[])
            .filter((_) => (declarationData?.garantiesEligibles ?? []).includes(_.garantie.crmId))
            .map((garantie: ReferentielsPiecesJustificatives) => (
              <AccordionTile
                title={garantie.garantie.nom}
                caption=""
                textZone={`Justificatifs ajoutés : 0/${garantie.piecesJustificatives.length ?? 0}`}
                key={garantie.garantie.nom}
              >
                <div
                  className="flex flex-col gap-M cursor-default pt-XS"
                  onClick={(e) => e.stopPropagation()}
                >
                  {garantie.piecesJustificatives.map((pj) => (
                    <RenderPJ
                      pjName={pj.nom}
                      onClickHelper={() =>
                        setPjInfo({
                          name: pj.nom,
                          description: pj.description,
                        })
                      }
                      docs={[]}
                      key={`${garantie.garantie.nom}_${pj.nom}}`}
                    />
                  ))}
                </div>
              </AccordionTile>
            ))}
      </div>

      {isMobile ? (
        <DrawerMobile
          title={pjInfo?.name ?? ""}
          isOpen={pjInfo !== undefined}
          content={stripHtmlTags(pjInfo?.description ?? "")}
          handleClose={() => setPjInfo(undefined)}
        />
      ) : (
        <Drawer
          title={pjInfo?.name ?? ""}
          isOpen={pjInfo !== undefined}
          content={stripHtmlTags(pjInfo?.description ?? "")}
          handleClose={() => setPjInfo(undefined)}
        />
      )}

      {isMobile ? (
        <DrawerMobile
          title={selectedGarantie?.nom ?? ""}
          content={modalContent}
          isOpen={openModal}
          handleClose={handleClose}
        />
      ) : (
        <ModalV2
          title={selectedGarantie?.nom ?? ""}
          content={modalContent}
          isOpen={openModal}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};
