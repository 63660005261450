import styles from "./BonPlanCard.module.scss";
import { useNavigate } from "react-router-dom";
import { Text } from "@roole/components-library";
import { classNames } from "utils/classNames";
import { BonPlanMobilite } from "domain/api-schemas";
import { useTracking } from "hooks/useTracking";
import { RoutePaths } from "routes/routePaths";

export interface BonPlanCardProps {
  size?: "small" | "large";
  bonPlan: BonPlanMobilite;
  className?: string;
}

const BonPlanCard: React.FC<BonPlanCardProps> = ({ size = "small", bonPlan, className }) => {
  const navigate = useNavigate();
  const { sendToDataLayer } = useTracking();

  return (
    bonPlan && (
      <div
        id={`bons-plans-${bonPlan.partner?.name
          ?.trim()
          .toLocaleLowerCase()
          .replace(/\s/g, "-")
          .replace(/-/g, "_")}`}
        className={classNames(styles.BonPlanCard, className)}
        onClick={(e) => {
          sendToDataLayer(e.currentTarget.id, false);
          navigate(`${RoutePaths.BON_PLANS}?id=${bonPlan.id}`);
        }}
      >
        <div
          className={classNames(
            styles.BonPlanIllustration,
            size === "large" && styles.BonPlanIllustration_large,
          )}
          style={{
            background: `linear-gradient(3deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%), url("${bonPlan.banner?.picture?.url}"), lightgray 50% / cover no-repeat`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className={styles.companyLogoWrapper}>
            <img
              src={bonPlan.partner?.logo?.url}
              alt="companyLogo"
              className={styles.companyLogo}
            />
          </div>

          {bonPlan.customLabels && bonPlan.customLabels.length > 0 && (
            <Text
              textVariant="body-em"
              as="span"
              className={styles.offerType}
              style={{
                backgroundColor: bonPlan.customLabels[0].backgroundColor,
                color: bonPlan.customLabels[0].color,
              }}
            >
              {bonPlan.customLabels[0].text}
            </Text>
          )}
        </div>

        <div className={styles.footer}>
          <Text
            as="h3"
            textVariant="body-em"
            className={styles.bonPlanName}
            dangerouslySetInnerHTML={{ __html: bonPlan.banner?.text }}
          />

          <Text as="p" textVariant="ui-1" className={styles.ctaMapText}>
            {bonPlan.banner?.map}
          </Text>
        </div>
      </div>
    )
  );
};

export default BonPlanCard;
