import {
  Avatar,
  ButtonV2,
  DrawerMobile,
  InputSelect,
  Message,
  ModalV2,
  RadioButtonGroup,
  Tag,
  TextV2,
} from "@roole/components-library";
import { declarationDataState } from "context/declarationState/atom";
import { GarantieV2 } from "domain/api-schemas";
import { FC, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import styles from "./GarantiesStep.module.scss";
import cssVariables from "styles/variables.module.scss";
import { useMatchMedia } from "hooks/useMatchMedia";
import { classNames } from "utils/classNames";
import { DeclarationParams } from "domain/api/Sinistre/EvenementApi";
import { useStepperContext } from "./StepperContext";
import { couvertureState } from "context/couvertureState/atom";
import { Couverture } from "domain/api-schemas";

const antiMalusId = "b43a8739-aab4-e711-9ac5-005056a41d51";
const aideAuxReparation = "1d0a1ca0-396a-e811-9acb-0050568cd8df";
const rachatDeFranchise = "bf3a8739-aab4-e711-9ac5-005056a41d51";

export const GarantiesStep: FC = () => {
  const { setActions, prevStep, nextStep } = useStepperContext();
  const [declarationData, setDeclarationData] = useRecoilState(declarationDataState);
  const [openModal, setOpenModal] = useState(false);
  const [selectedGarantie, setSelectedGarantie] = useState<GarantieV2 | null>(null);
  const [answer, setAnswer] = useState("");
  const [needOneEligible, setNeedOneEligible] = useState(false);
  const [resetError, setResetError] = useState(false);
  const [couverture, _] = useRecoilState(couvertureState);

  const {
    isVolExpire,
    isVehiculeRetrouve,
    isVehiculeReparable,
    garantiesEligibles,
    garantiesNonEligibles,
  } = declarationData as DeclarationParams;

  const isMobile = useMatchMedia(cssVariables.breakpointMobile);

  const garanties = declarationData?.typeEvenement?.garanties?.filter((garantie) => {
    if (declarationData?.typeEvenement?.code === "VOL") {
      if (
        (!isVolExpire &&
          isVehiculeRetrouve &&
          !isVehiculeReparable &&
          garantie.code === "AIDREP") ||
        (!isVolExpire && isVehiculeRetrouve && isVehiculeReparable && garantie.code === "IF") ||
        (isVolExpire && garantie.code === "AIDREP")
      ) {
        return false;
      }
    }

    return true;
  });

  const checkGarantie = (garantie: GarantieV2) => {
    setSelectedGarantie(garantie);
    setOpenModal(true);
  };

  const handleClose = () => {
    setSelectedGarantie(null);
    setOpenModal(false);
  };

  const checkEligible = (isEligible: boolean) => {
    if (selectedGarantie) {
      const _garantiesEligibles = new Set(garantiesEligibles ?? []);
      const _garantiesNonEligibles = new Set(garantiesNonEligibles ?? []);
      const crmId = selectedGarantie.crmId;

      if (isEligible) {
        _garantiesEligibles.add(crmId);
        _garantiesNonEligibles.delete(crmId);
      } else {
        _garantiesNonEligibles.add(crmId);
        _garantiesEligibles.delete(crmId);
      }

      setDeclarationData({
        ...(declarationData as DeclarationParams),
        garantiesEligibles: Array.from(_garantiesEligibles),
        garantiesNonEligibles: Array.from(_garantiesNonEligibles),
      });

      setOpenModal(false);
    }
  };

  const modalContent = (
    <div>
      <TextV2 textVariant="heading-3" as="h3">
        Complétez les informations
      </TextV2>
      <div className={styles.modalButtons}>
        <ButtonV2 variant="secondary-dark" onClick={handleClose}>
          Annuler
        </ButtonV2>
        <ButtonV2
          variant="primary-brand"
          onClick={() => {
            setResetError(true);
            checkEligible(true);
          }}
        >
          Vérifier mon éligibilité (OUI)
        </ButtonV2>
        <ButtonV2
          variant="primary-brand"
          onClick={() => {
            setResetError(true);
            checkEligible(false);
          }}
        >
          Vérifier mon éligibilité (NON)
        </ButtonV2>
      </div>
    </div>
  );

  useEffect(() => {
    if (!declarationData) {
      return;
    }

    setActions({
      buttonPrimary: {
        variant: "primary-brand",
        children: "Continuer",
        onClick: () => {
          if (
            !!declarationData.garantiesEligibles?.length ||
            declarationData.garantiesNonEligibles?.length === garanties?.length
          ) {
            nextStep();
            setNeedOneEligible(false);
            setResetError(false);
          } else {
            setNeedOneEligible(true);
            setResetError(false);
          }
        },
      },
      buttonSecondary: {
        variant: "secondary-dark",
        children: "Retour",
        onClick: () => prevStep(),
      },
    });
  }, [declarationData, prevStep, nextStep, setActions, garanties?.length]);

  return (
    <div className={styles.content}>
      <TextV2 textVariant="heading-2" as="h2">
        Vos garanties
      </TextV2>

      <TextV2>Vérifiez votre éligibilité aux garanties dont vous souhaitez bénéficier :</TextV2>

      <div className={styles.garanties}>
        {garanties?.map((garantie) => {
          const isNotEligibiliteVerified =
            !garantiesEligibles?.includes(garantie.crmId) &&
            !garantiesNonEligibles?.includes(garantie.crmId);
          const isEligibleSelected = !!garantiesEligibles?.includes(garantie.crmId);

          const isEligible = isNotEligibiliteVerified ? undefined : isEligibleSelected;

          const avatarNotEligible = isEligible === false ? "shield-off" : "shield";
          const avatarIcon = isEligible ? "shield-checked" : avatarNotEligible;

          if (
            garantie.crmId === antiMalusId ||
            (garantie.crmId === aideAuxReparation && couverture === Couverture.TousRisques) ||
            (garantie.crmId === rachatDeFranchise && couverture === Couverture.AuTiers) ||
            (garantie.crmId === aideAuxReparation &&
              "tous-risques" === declarationData?.couvertureAssurance) ||
            (garantie.crmId === rachatDeFranchise &&
              "au-tiers" === declarationData?.couvertureAssurance)
          ) {
            return <></>;
          }

          return (
            <div
              key={garantie.crmId}
              className={classNames(
                styles.tileGarantie,
                isEligible && styles.eligible,
                isEligible === false && styles.nonEligible,
                needOneEligible && isEligible === undefined && !resetError && styles.needEligible,
              )}
            >
              <div>
                <Avatar
                  type="icon"
                  icon={avatarIcon}
                  size="L"
                  variant={isEligible ? "validation" : "neutral"}
                />
              </div>

              <div style={{ width: "100%" }}>
                <div
                  className={classNames(
                    styles.tileGarantieContent,
                    isEligible === false && styles.nonEligible,
                  )}
                >
                  <div className={styles.tileGarantieTitle}>
                    <TextV2
                      textVariant="heading-4"
                      as="h4"
                      color={isEligible === false && "secondary"}
                    >
                      {garantie.nom}
                    </TextV2>

                    {isEligible !== undefined && (
                      <div className={styles.tileGarantieTag}>
                        <Tag
                          label={isEligible ? "Éligible" : "Non éligible"}
                          type={isEligible ? "validation" : "neutral"}
                        />
                      </div>
                    )}
                  </div>

                  <TextV2 textVariant="body-medium-em" color={isEligible === false && "secondary"}>
                    {garantie.plafondMessage}
                  </TextV2>

                  <p
                    className={classNames(isEligible === false && styles.nonEligible)}
                    dangerouslySetInnerHTML={{ __html: garantie.description }}
                  />
                </div>

                {isEligible === undefined ? (
                  <ButtonV2
                    variant="primary-brand"
                    onClick={() => checkGarantie(garantie)}
                    size="small"
                  >
                    Vérifier mon éligibilité
                  </ButtonV2>
                ) : (
                  <ButtonV2
                    variant="secondary-dark"
                    onClick={() => checkGarantie(garantie)}
                    size="small"
                  >
                    Modifier mes informations
                  </ButtonV2>
                )}
              </div>
            </div>
          );
        })}

        {garanties?.map((garantie) => {
          const isNotEligibiliteVerified =
            !garantiesEligibles?.includes(garantie.crmId) &&
            !garantiesNonEligibles?.includes(garantie.crmId);
          const isEligibleSelected = !!garantiesEligibles?.includes(garantie.crmId);

          const isEligible = isNotEligibiliteVerified ? undefined : isEligibleSelected;

          const avatarNotEligible = isEligible === false ? "shield-off" : "shield";
          const avatarIcon = isEligible ? "shield-checked" : avatarNotEligible;
          const isAntiMalus = garantie.crmId === antiMalusId;

          return isAntiMalus ? (
            <div style={{ marginTop: "32px" }}>
              <TextV2 textVariant="heading-4" as="h2">
                Avez-vous eu un malus suite à cet accident ?
              </TextV2>
              <TextV2 textVariant="body-medium" as="p">
                Le malus est une pénalité appliquée en cas d’accident responsable, pouvant entrainer
                l’augmentation de votre prime d’assurance<sup>1</sup>.
              </TextV2>
              <div style={{ marginTop: "32px", marginBottom: "20px" }}>
                {isMobile ? (
                  <InputSelect
                    label="Sélectionnez une réponse"
                    value={answer}
                    setValue={(value: string) => {
                      if (value === "Oui") {
                        setAnswer("Oui");
                      } else if (value === "Je ne sais pas") {
                        setAnswer("Je ne sais pas");
                      } else {
                        setAnswer("Non");
                      }
                    }}
                    values={["Oui", "Non", "Je ne sais pas"]}
                  />
                ) : (
                  <RadioButtonGroup
                    direction="row"
                    elements={[
                      { label: "Oui", value: "oui" },
                      { label: "Non", value: "non" },
                      { label: "Je ne sais pas", value: "je-ne-sais-pas" },
                    ]}
                    name="malus"
                    onChange={(e) => {
                      if (e === 0) {
                        setAnswer("Oui");
                      } else if (e === 2) {
                        setAnswer("Je ne sais pas");
                      } else {
                        setAnswer("Non");
                      }
                    }}
                    variant="tile"
                  />
                )}
              </div>

              {answer === "Oui" && (
                <div
                  key={garantie.crmId}
                  className={classNames(
                    styles.tileGarantie,
                    isEligible && styles.eligible,
                    isEligible === false && styles.nonEligible,
                  )}
                >
                  <div>
                    <Avatar
                      type="icon"
                      icon={avatarIcon}
                      size="L"
                      variant={isEligible ? "validation" : "neutral"}
                    />
                  </div>

                  <div style={{ width: "100%" }}>
                    <div
                      className={classNames(
                        styles.tileGarantieContent,
                        isEligible === false && styles.nonEligible,
                      )}
                    >
                      <div className={styles.tileGarantieTitle}>
                        <TextV2
                          textVariant="heading-4"
                          as="h4"
                          color={isEligible === false && "secondary"}
                        >
                          {garantie.nom}
                        </TextV2>

                        {isEligible !== undefined && (
                          <div className={styles.tileGarantieTag}>
                            <Tag
                              label={isEligible ? "Éligible" : "Non éligible"}
                              type={isEligible ? "validation" : "neutral"}
                            />
                          </div>
                        )}
                      </div>

                      <TextV2
                        textVariant="body-medium-em"
                        color={isEligible === false && "secondary"}
                      >
                        {garantie.plafondMessage}
                      </TextV2>

                      <p
                        className={classNames(isEligible === false && styles.nonEligible)}
                        dangerouslySetInnerHTML={{ __html: garantie.description }}
                      />
                    </div>

                    {isEligible === undefined ? (
                      <ButtonV2
                        variant="primary-brand"
                        onClick={() => checkGarantie(garantie)}
                        size="small"
                      >
                        Vérifier mon éligibilité
                      </ButtonV2>
                    ) : (
                      <ButtonV2
                        variant="secondary-dark"
                        onClick={() => checkGarantie(garantie)}
                        size="small"
                      >
                        Modifier mes informations
                      </ButtonV2>
                    )}
                  </div>
                </div>
              )}

              {answer === "Je ne sais pas" && (
                <div>
                  <TextV2 textVariant="body-small">
                    Nous vous conseillons de contacter votre assurance principale pour obtenir
                    l’information.
                  </TextV2>
                  <Message
                    message={
                      <TextV2 textVariant="body-small">
                        En cas de malus, nous pouvons couvrir jusqu’à 500 €, l’augmentation de votre
                        prime annuelle d’assurance grâce à la garantie anti-malus.
                      </TextV2>
                    }
                    type="information"
                    icon="info"
                  />
                </div>
              )}
              <div style={{ marginTop: "16px" }}>
                <TextV2 textVariant="body-small" color="secondary">
                  <sup>1</sup> La prime d’assurance est le montant total annuel que vous payez à
                  votre assurance principale pour bénéficier d’une couverture en cas de sinistre.
                </TextV2>
              </div>
            </div>
          ) : (
            <></>
          );
        })}
      </div>
      {needOneEligible && !resetError && (
        <div className={styles.needOneEligible}>
          <Message
            icon="info"
            message={
              <TextV2 textVariant="body-small" color="error">
                Aucune des garanties que vous avez vérifiées n'est éligible. Veuillez vérifier les
                garanties restantes pour continuer.
              </TextV2>
            }
            type="error"
          />
        </div>
      )}
      {isMobile ? (
        <DrawerMobile
          title={selectedGarantie?.nom ?? ""}
          content={modalContent}
          isOpen={openModal}
          handleClose={handleClose}
        />
      ) : (
        <ModalV2
          title={selectedGarantie?.nom ?? ""}
          content={modalContent}
          isOpen={openModal}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};
