import {
  Avatar,
  InputAreaV2,
  Message,
  RadioButtonGroup,
  TextV2,
  Tile,
} from "@roole/components-library";
import { FC, useEffect } from "react";
import { useRecoilState } from "recoil";
import { declarationDataState } from "context/declarationState/atom";
import { formatToFrenchLongDate } from "utils/formatDate";
import { DeclarationParams } from "domain/api/Sinistre/EvenementApi";
import { classNames } from "utils/classNames";
import { useStepperContext } from "../StepperContext";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "routes/routePaths";

const yesNoRadioElements = [
  {
    label: "Oui",
    value: "true",
  },
  {
    label: "Non",
    value: "false",
  },
];
const yesNoIdkRadioElements = [
  {
    label: "Oui",
    value: "true",
  },
  {
    label: "Non",
    value: "false",
  },
  {
    label: "Je ne sais pas",
    value: "null",
  },
];
const rappelEtapesEnCasDeVol = [
  {
    icon: "acheminement-vers-autorites",
    title: "Déclarer au commissariat le plus proche",
    text: "Déclarer au commissariat le plus proche Obtenez une copie du procès-verbal de la plainte, qui sera nécessaire pour votre déclaration de sinistre.",
  },
  {
    icon: "phone-call",
    title: "Contacter votre assureur sous 48h",
    text: "Fournissez-leur tous les détails nécessaires.",
  },
  {
    icon: "calendar",
    title: "Déclarer-nous le vol",
    text: "Si votre véhicule n'a pas été retrouvé après un délai de 30 jours, réalisez votre déclaration afin de bénéficier de vos garanties.",
  },
];
const couvertureAssuranceRadioElements = [
  {
    label: "Tous risques",
    value: "tous-risques",
  },
  {
    label: "Au tiers",
    value: "au-tiers",
  },
];

export const DetailVolStep: FC = () => {
  const { prevStep, nextStep, setActions } = useStepperContext();
  const [declarationData, setDeclarationData] = useRecoilState(declarationDataState);
  const navigate = useNavigate();
  const isVolExpire = !!declarationData?.isVolExpire;
  const showIsReparableSection =
    declarationData?.isVehiculeRetrouve ||
    (isVolExpire &&
      declarationData?.isVehiculeRetrouve !== undefined &&
      declarationData?.isVehiculeRetrouve !== false);
  const showRappelStepsEnCasVol =
    !isVolExpire &&
    declarationData?.isVehiculeRetrouve !== undefined &&
    declarationData?.isVehiculeRetrouve !== true;
  const showDescriptionAndAssuranceSections =
    (declarationData?.isVehiculeRetrouve &&
      typeof declarationData?.isVehiculeReparable === "boolean") ||
    (declarationData?.isVehiculeRetrouve === false && isVolExpire);

  useEffect(() => {
    if (!declarationData) {
      return;
    }

    let buttonPrimary: any = null;

    const revenirPlusTard = {
      variant: "primary-brand",
      children: "Revenir plus tard",
      onClick: () => navigate(RoutePaths.SINISTRES),
    };
    const incomplete = {
      variant: "primary-brand",
      children: "Continuer",
      onClick: () => alert("Veuillez tout remplir"),
    };

    if (
      // Si c'est expiré et que il n'a pas de retour de l'expert
      (isVolExpire && declarationData.isVehiculeReparable === null) ||
      // Si c'est pas expiré et qu'e 'il n'a pas retrouvé son véhicule ou de retour de l'expert
      (!isVolExpire &&
        (!declarationData.isVehiculeRetrouve ||
          (declarationData.isVehiculeReparable !== undefined &&
            declarationData.isVehiculeReparable === null)))
    ) {
      buttonPrimary = revenirPlusTard;
    }

    if (
      buttonPrimary === null &&
      (!declarationData.description?.length ||
        declarationData.couvertureAssurance === undefined ||
        declarationData.isDeclareAssuranceAccident === undefined)
    ) {
      buttonPrimary = incomplete;
    }

    setActions({
      buttonPrimary: !!buttonPrimary
        ? buttonPrimary
        : {
            variant: "primary-brand",
            children: "Continuer",
            onClick: () => nextStep(),
          },
      buttonSecondary: {
        variant: "secondary-dark",
        children: "Retour",
        onClick: () => {
          setDeclarationData({
            ...declarationData,
            isVehiculeRetrouve: undefined,
            isVehiculeReparable: undefined,
            description: undefined,
            couvertureAssurance: undefined,
            isDeclareAssuranceAccident: undefined,
          } as DeclarationParams);
          prevStep();
        },
      },
    });
  }, [declarationData, isVolExpire, navigate, nextStep, prevStep, setActions, setDeclarationData]);

  return (
    <>
      <Tile
        avatar={declarationData?.typeEvenement?.icone}
        icon="edit"
        label="Modifier"
        subtitle={declarationData?.typeEvenement?.nom ?? ""}
        title={
          declarationData?.dateEvenement
            ? `Sinistre du ${formatToFrenchLongDate(new Date(declarationData?.dateEvenement))}`
            : ""
        }
        onClick={() => prevStep()}
      />

      <div className="flex flex-col gap-XS pt-M">
        <TextV2 textVariant="heading-2" as="h2">
          État du véhicule
        </TextV2>

        <div className="flex flex-col gap-M pb-XS">
          <div className="flex flex-col gap-XS">
            <TextV2 textVariant="body-medium" as="p">
              Votre véhicule a-t’il été retrouvé à date ?
            </TextV2>

            <div className="w-full md:w-fit">
              <RadioButtonGroup
                name="isVehiculeRetrouve"
                direction="row"
                elements={yesNoIdkRadioElements}
                onChange={(index) => {
                  const _ = JSON.parse(yesNoIdkRadioElements[index].value);
                  const newValue: DeclarationParams = {
                    ...declarationData,
                    isVehiculeRetrouve: _,
                  } as DeclarationParams;
                  if (!_) {
                    newValue.isVehiculeReparable = undefined;
                  }
                  setDeclarationData(newValue);
                }}
                variant="tile"
                checked={[
                  declarationData?.isVehiculeRetrouve === true,
                  declarationData?.isVehiculeRetrouve === false,
                  declarationData?.isVehiculeRetrouve === null,
                ]}
              />
            </div>

            {showRappelStepsEnCasVol && (
              <>
                <Message
                  message={
                    <TextV2>
                      La perte est considérée comme définitive 30 jours après le vol. Nous vous
                      invitons à <b>patienter jusqu’à la fin de cette période</b> avant de réaliser
                      votre déclaration. Besoin d’aide ? Contactez-nous (01 46 90 37 00).
                    </TextV2>
                  }
                  icon="alert-circle"
                  type="warning"
                />

                <div className="flex flex-col gap-S border border-default rounded-XS p-S w-full md:w-fit">
                  <TextV2 textVariant="heading-4">Rappel des étapes à suivre en cas de vol</TextV2>

                  <div className="flex flex-col gap-XS">
                    {rappelEtapesEnCasDeVol.map(({ icon, title, text }) => (
                      <div className="flex gap-XS" key={title}>
                        <div className="w-fit h-fit">
                          <Avatar size="M" type="icon" variant="brand_light" icon={icon} />
                        </div>

                        <div className="flex flex-col gap-3XS">
                          <TextV2>
                            <b>{title}</b>
                            <br />
                            {text}
                          </TextV2>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>

          {showIsReparableSection && (
            <div className="flex flex-col gap-XS">
              <TextV2 textVariant="body-medium" as="p">
                D’après l’expertise, votre véhicule est-il considéré comme réparable ?
              </TextV2>

              <div className="w-full md:w-fit">
                <RadioButtonGroup
                  name="isVehiculeReparable"
                  direction="row"
                  elements={yesNoIdkRadioElements}
                  onChange={(index) => {
                    const _ = JSON.parse(yesNoIdkRadioElements[index].value);
                    const newValue: DeclarationParams = {
                      ...declarationData,
                      isVehiculeReparable: _,
                    } as DeclarationParams;

                    setDeclarationData(newValue);
                  }}
                  variant="tile"
                  checked={[
                    declarationData?.isVehiculeReparable === true,
                    declarationData?.isVehiculeReparable === false,
                    declarationData?.isVehiculeReparable === null,
                  ]}
                />
              </div>

              {declarationData?.isVehiculeReparable === null && (
                <Message
                  message={
                    <TextV2>
                      Pour poursuivre votre déclaration, vous devez renseigner l’état de votre
                      véhicule. Veuillez attendre le rapport de l’expert pour poursuivre votre
                      déclaration.
                    </TextV2>
                  }
                  icon="alert-circle"
                  type="warning"
                />
              )}
            </div>
          )}
        </div>

        {showDescriptionAndAssuranceSections && (
          <>
            <TextV2 textVariant="heading-2" as="h2">
              Que s’est-il passé ?
            </TextV2>

            <TextV2>
              Décrivez-nous précisément : quels sont les dégâts, l’origine, si vous êtes
              responsable, si un tiers est impliqué...
            </TextV2>

            <Message
              message={
                <TextV2 textVariant="body-small">
                  <b>Par exemple ·</b> En rentrant chez moi hier, j’ai percuté le véhicule devant
                  moi. Le conducteur n’est pas blessé. Mon pare-choc est abîmé.
                </TextV2>
              }
              type="information"
              icon="info"
            />

            <div className="pb-S">
              <InputAreaV2
                label="Saisissez les détails de votre sinistre"
                value={declarationData?.description ?? ""}
                onChange={(e) =>
                  setDeclarationData({
                    ...declarationData,
                    description: e.target.value,
                  } as DeclarationParams)
                }
                maxLength={1000}
              />
            </div>

            <TextV2 textVariant="heading-2" as="h2">
              Assurance principale
            </TextV2>

            <div className="flex flex-col gap-M pb-3XS w-full md:w-fit">
              <div className="flex flex-col gap-XS">
                <TextV2 textVariant="body-medium" as="p">
                  Quelle est la couverture de votre assurance principale ?
                </TextV2>

                <RadioButtonGroup
                  direction="row"
                  elements={couvertureAssuranceRadioElements}
                  name="couvertureAssurance"
                  onChange={(index) =>
                    setDeclarationData({
                      ...declarationData,
                      couvertureAssurance: couvertureAssuranceRadioElements[index].value as any,
                    } as DeclarationParams)
                  }
                  variant="tile"
                  checked={[
                    declarationData?.couvertureAssurance === "tous-risques",
                    declarationData?.couvertureAssurance === "au-tiers",
                  ]}
                />
              </div>
              <div className={classNames("flex flex-col gap-XS")}>
                <TextV2 textVariant="body-medium" as="p">
                  Avez-vous déclaré votre accident à votre assurance principale ?
                </TextV2>

                <RadioButtonGroup
                  direction="row"
                  elements={yesNoRadioElements}
                  name="isDeclareAssuranceAccident"
                  onChange={(index) => {
                    const _ = JSON.parse(yesNoRadioElements[index].value);
                    setDeclarationData({
                      ...declarationData,
                      isDeclareAssuranceAccident: _,
                    } as DeclarationParams);
                  }}
                  variant="tile"
                  checked={[
                    declarationData?.isDeclareAssuranceAccident === true,
                    declarationData?.isDeclareAssuranceAccident === false,
                  ]}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
