import { FC, useState, useEffect } from "react";
import {
  TextV2,
  Tabs,
  IconV2,
  LoaderV2,
  ButtonV2,
  DrawerMobile,
  ModalV2,
  Avatar,
} from "@roole/components-library";
import styles from "./Page.module.scss";
import { useRecoilValueLoadable, useRecoilRefresher_UNSTABLE } from "recoil";
import { listEvenementsQuery } from "context/evenementsState/selector";
import { LoadableState } from "context/state.types";
import { filterEvenements } from "domain/service/Sinistre/useEvenement";
import { Event } from "./Event";
import { RoutePaths } from "routes/routePaths";
import { useMatchMedia } from "hooks/useMatchMedia";
import cssVariables from "styles/variables.module.scss";
import { ReactComponent as IconFonctionnaliteIndisponible } from "images/icons/fonctionnalite-indispo.svg";
import { useTracking } from "hooks/useTracking";

export const Page: FC = () => {
  const { sendToDataLayer } = useTracking();
  const evenementsState = useRecoilValueLoadable(listEvenementsQuery);

  const refreshListEvenements = useRecoilRefresher_UNSTABLE(listEvenementsQuery);

  const isLoading = evenementsState.state === LoadableState.Loading;

  const evenements =
    evenementsState.state === LoadableState.Has_Value ? evenementsState.contents : [];

  const { evenementsEncours, evenementsTermines } = filterEvenements(evenements ?? []);

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const [modalOpen, setModalOpen] = useState(false);

  const isMobile = useMatchMedia(cssVariables.breakpointMobile);

  useEffect(() => {
    refreshListEvenements();
  }, [refreshListEvenements]);

  const modalContent = (
    <div className={styles.modalContainer}>
      <IconFonctionnaliteIndisponible className={styles.centerContent} />
      <div style={{ textAlign: "center" }}>
        <TextV2 textVariant="heading-2" as="h2" extraCss={{ marginBottom: 8 }}>
          Fonctionnalité indisponible
        </TextV2>
        <TextV2 textVariant="body-medium" as="p">
          Cette fonctionnalité est en cours d'amélioration. Elle sera bientôt de nouveau disponible.
        </TextV2>
      </div>
      <div className={styles.contactContainer}>
        <div style={{ display: "flex", gap: 12 }}>
          <div>
            <Avatar size="M" icon="phone" variant="brand_light" type={"icon"} />
          </div>
          <TextV2 textVariant="heading-5">
            Contacter le service client Roole
            <TextV2 textVariant="body-medium" as="p" extraCss={{ marginTop: 4 }}>
              Déclarez votre sinistre auprès de l'un de nos conseillers. Par la suite, vous pourrez
              suivre et compléter votre dossier directement depuis votre compte personnel.
            </TextV2>
            <div className={styles.contactDiv}>
              <TextV2 textVariant="body-medium">
                Contactez le <TextV2 textVariant="body-medium-em">01 46 90 37 00</TextV2>
              </TextV2>
            </div>
          </TextV2>
        </div>
      </div>
      <div className={styles.centerContent}>
        <ButtonV2 variant="primary-brand" size="large" onClick={() => setModalOpen(false)}>
          Fermer
        </ButtonV2>
      </div>
    </div>
  );

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}>
        <TextV2 textVariant="heading-1" as="h1" className={styles.headerContainerTitle}>
          Mes sinistres
        </TextV2>
        <div className={styles.buttons}>
          <ButtonV2
            size="small"
            onClick={(e) => {
              sendToDataLayer(e.currentTarget.id, true);
              setModalOpen(true);
            }}
            variant="primary-light"
            icon="auto-shop"
            iconBeforeText={true}
            id="sinisterDeclare_onbording"
          >
            Déclarer un sinistre
          </ButtonV2>
          <ButtonV2
            size="small"
            variant="primary-brand"
            onClick={(e) => {
              sendToDataLayer(e.currentTarget.id, true);
              window.open(`${RoutePaths.AIDE}/?question=une-urgence-besoin-d-assistance`, "_blank");
            }}
            icon="zap-on"
            iconBeforeText={true}
            id="navigate_to"
          >
            J'ai une urgence
          </ButtonV2>
        </div>
      </div>
      <div className={styles.container}>
        <TextV2 textVariant="heading-2" as="h2">
          Suivre mes dossiers
        </TextV2>
        <div className={styles.tabs}>
          <Tabs
            tabs={[
              {
                text: "En cours",
              },
              {
                text: "Terminés",
              },
            ]}
            onTabChange={(index: number) => setActiveTabIndex(index)}
          />
        </div>
        {isLoading && <LoaderV2 />}
        {!isLoading &&
          (activeTabIndex === 0 ? (
            evenementsEncours?.length === 0 ? (
              <div className={styles.containerEmpty}>
                <div className={styles.iconEmpty}>
                  <IconV2 name="accelerate" size={32} />
                </div>
                <div>
                  <TextV2 textVariant="heading-4" as="h4">
                    Aucun dossier en cours
                  </TextV2>
                  <TextV2
                    textVariant="body-medium"
                    as="p"
                    color="secondary"
                    className={styles.messageEmpty}
                  >
                    C'est ici que vous suivez et complétez vos dossiers, une fois vos sinistres
                    déclarés.
                    <br /> Vous pouvez retrouvez l'ensemble de vos dossiers réglés et refusés dans
                    l'onglet Terminés.
                  </TextV2>
                </div>
              </div>
            ) : (
              <div>
                {evenementsEncours.map((evenement) => {
                  return <Event key={evenement.crmId} evenement={evenement} />;
                })}
              </div>
            )
          ) : evenementsTermines?.length === 0 ? (
            <div className={styles.containerEmpty}>
              <div className={styles.iconEmpty}>
                <IconV2 name="accelerate" size={32} />
              </div>
              <div>
                <TextV2 textVariant="heading-4" as="h4">
                  Aucun dossier terminé
                </TextV2>
                <TextV2
                  textVariant="body-medium"
                  as="p"
                  color="secondary"
                  className={styles.messageEmpty}
                >
                  C'est ici que vous retrouvez l'historique de vos dossiers réglés et refusés.
                </TextV2>
              </div>
            </div>
          ) : (
            <div>
              {evenementsTermines.map((evenement) => {
                return <Event key={evenement.crmId} evenement={evenement} />;
              })}
            </div>
          ))}
      </div>
      {isMobile ? (
        <DrawerMobile
          isOpen={modalOpen}
          handleClose={() => setModalOpen(false)}
          title={""}
          content={modalContent}
        />
      ) : (
        <ModalV2
          title={""}
          isOpen={modalOpen}
          handleClose={() => setModalOpen(false)}
          content={modalContent}
        />
      )}
    </div>
  );
};
